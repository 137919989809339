<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { useModeStore } from '../store/mode.js';
import useIsMobile from '../composables/isMobile.js';
import { useAllSearchResultStore } from '../store/allSearchResult.js';
import { useWorkspaceStore } from '../store/workspace.js';
import { useNotificationTopBarStore } from '../store/notificationTopBar.js';
import { getContentTypeLabel } from '../utils/commonFunctions.js';
import { getStrokeColor, getModeStrokeColor } from '../utils/commonFunctions.js';
// import { useRouter } from 'vue-router';

const modeStore = useModeStore();
const strokeColor = getStrokeColor();
const modeStrokeColor = getModeStrokeColor();
const { isMobile } = useIsMobile();
const allSearchResultStore = useAllSearchResultStore();
const workspaceStore = useWorkspaceStore();
const notificationTopBarStore = useNotificationTopBarStore();
// const router = useRouter();
// const isHovered = ref(false);
const hoveredDocument = ref(null);
const isAddRemoveWorkspaceLoading = ref(false);

// Import components
import PlaylistCard from '../components/PlaylistCard.vue'
import TeachersCard from '../components/TeachersCard.vue'
import DocumentsCard from '../components/DocumentsCard.vue'
import NotificationTopBar from '../components/NotificationTopBar.vue';
// const goToDocument = (id) => {
//     router.push({ name: 'DocumentDetailed', params: { id: id } });
// }

const addToWorkspace = async (id) => {
    try {
        isAddRemoveWorkspaceLoading.value = true;
        const response = await axios.post('api/v1/workspaces/asset_broadcasts', {
            asset_broadcast_id: id
        });
        // Find and update the document
        const document = allSearchResultStore.allSearchResult.assets.find(doc => doc.id === id);
        if (document) {
            document.has_added_to_workspace = true;
        }
        // Check if the response indicates success
        if (response.status === 200 || response.status === 201) { 
            console.log('Successfully added asset broadcast to workspace:', response);
            // Set up notification after successful addition
            notificationTopBarStore.setIdUsedInAction(id);
            notificationTopBarStore.setNotificationMessage('Ditambah ke Ruang Kerja');
            notificationTopBarStore.setNotificationType('addAssetBroadcastToWorkspace');
            notificationTopBarStore.setIsShowNotificationTopBar(true);

            // Emit an event to refresh the asset API
            //emit('refresh-asset-api');
        } else {
            console.error('Failed to add asset broadcast to workspace:', response);
        }
    } catch (error) {
        console.error('Error adding asset broadcast to workspace:', error);
    } finally {
        isAddRemoveWorkspaceLoading.value = false;
    }
}


const removeFromWorkspace = async (id) => {
    try {
        isAddRemoveWorkspaceLoading.value = true;
        await axios.delete(`api/v1/workspaces/asset_broadcasts/${id}`);
        // Find and update the document
        const document = allSearchResultStore.allSearchResult.assets.find(doc => doc.id === id);
        if (document) {
            document.has_added_to_workspace = false;
        }

        notificationTopBarStore.setIdUsedInAction(id);
        notificationTopBarStore.setNotificationMessage('Dipindah dari Ruang Kerja');
        notificationTopBarStore.setNotificationType('removeAssetBroadcastFromWorkspace');
        notificationTopBarStore.setIsShowNotificationTopBar(true);
    } catch (error) {
        console.error('Error removing playlist from workspace:', error);
    } finally {
        isAddRemoveWorkspaceLoading.value = false;
    }
}

const refreshPlaylistInAllSearchResult = async () => {
    try {
        if(workspaceStore.action === 'add') {
            const playlist = allSearchResultStore.allSearchResult.playlists.find(playlist => playlist.id === workspaceStore.playlistId);
            if (playlist) {
                playlist.has_added_to_workspace = true;
            }
        } else if(workspaceStore.action === 'remove') {
            const playlist = allSearchResultStore.allSearchResult.playlists.find(playlist => playlist.id === workspaceStore.playlistId);
            if (playlist) {
                playlist.has_added_to_workspace = false;
            }
        } else {
            console.error('Invalid workspace action:', workspaceStore.action);
        }
    } catch (error) {
        console.error('Error refreshing playlist in all search result:', error);
    }
}

const fetchAllSearchResult = () => {
    // Fetch data from API
    axios.get('api/v1/search', {
        params: {
            query: allSearchResultStore.searchQuery
        }
    })
    .then((response) => {
        console.log('fetchAllSearchResult',response.data);
        allSearchResultStore.setAllSearchResult(response.data);
        // searchResultDocumentList.value = response.data.asset_broadcasts;
        // searchResultTeachers.value = response.data.users;
        allSearchResultStore.setIsSearchResultLoading(false);

    })
    .catch((error) => {
        console.error(error);
    })
}

onMounted(() => {
    console.log('searchQuery',allSearchResultStore.searchQuery)
    fetchAllSearchResult();
});
</script>

<template>
    <div class="overflow-x-scroll hide-scrollbar">
        <NotificationTopBar v-show="notificationTopBarStore.isShowNotificationTopBar" />
        <div v-if="allSearchResultStore.isSearchResultLoading" class="flex items-center justify-center w-full gap-2 p-6">
            <div>{{ $t('general.loading')}}</div>
            <span class="loading loading-bars loading-sm"></span>
        </div>
        <section v-else class="rounded-xl p-4 m-4 bg-transparent" :class="{'text-black': modeStore.selectedMode === 'light', 'text-white': modeStore.selectedMode === 'dark'}">
            <div v-if="allSearchResultStore.allSearchResult.assets" class="md:grid md:grid-cols-12 gap-4">
                <div class="md:col-span-3 max-w-[300px]">
                    <div :class="{'text-black bg-[#E5E7EB]': modeStore.selectedMode === 'light', 'text-white bg-black': modeStore.selectedMode === 'dark'}" class="p-4 rounded-lg">
                        <h2 :class="{'mobile-title': isMobile, 'title': !isMobile}">{{ $t('search.topResult')}}</h2>
                        <DocumentsCard
                            class="mt-2"
                            :key="allSearchResultStore.allSearchResult.assets[0].id"
                            :id="allSearchResultStore.allSearchResult.assets[0].id"
                            :image="allSearchResultStore.allSearchResult.assets[0].thumbnails[0].url"
                            :topic="allSearchResultStore.allSearchResult.assets[0].title"
                            :postedTime="allSearchResultStore.allSearchResult.assets[0].created_at_formatted"
                            :tags="allSearchResultStore.allSearchResult.assets[0].content_type"
                            :isAddedToWorkspace="allSearchResultStore.allSearchResult.assets[0].has_added_to_workspace"
                            :contentType="allSearchResultStore.allSearchResult.assets[0].content_type"
                        />
                    </div>
                </div>
                <div class="md:col-span-9 min-w-[600px] overflow-x-scroll">
                    <div :class="{'text-black': modeStore.selectedMode === 'light', 'text-white': modeStore.selectedMode === 'dark'}" class="flex gap-2 py-3">
                        <h2 :class="{'mobile-title': isMobile, 'title': !isMobile}">{{ $t('title.DELIMaCollection')}}</h2>
                        <router-link :to="{name: 'Document Page'}" class="title-action flex items-center"><i class="fa-solid fa-chevron-right fa-lg px-2"></i></router-link>
                    </div>
                    <!-- <div @click="goToDocument(document.id)"
                         v-for="document in allSearchResultStore.allSearchResult.asset_broadcasts" :key="document.id"
                        class="border-t py-4 cursor-pointer hover:bg-gray-50 hover:bg-opacity-10">
                        <div class="grid grid-cols-12">
                            <div class="col-span-8 flex gap-2">
                                <img :src="document.thumbnails[0].url" alt="course img" class="max-h-16 rounded-md">
                                <div class="my-auto">
                                    <p class="my-auto font-semibold">{{ document.title }}</p>
                                    <p class="text-sm">{{ document.courseLevel }}</p>
                                </div>
                            </div>
                            <div class="col-span-3 text-center my-auto">
                                <div             
                                :class="{
                                    'bg-[#FEF3C7] text-[#92400E]': document.content_type === 1,
                                    'bg-green-100 text-green-800': document.content_type === 2,
                                    'bg-gray-100 text-gray-800': document.content_type === 3
                                }" class="badge badge-lg border-0 rounded-xl">
                                    {{ getContentTypeLabel(document.content_type) }}
                                </div>
                            </div>
                            <div class="col-span-1 text-center my-auto">{{ document.courseDuration }}</div>
                        </div>
                    </div> -->
                    <table :class="{'text-black bg-white': modeStore.selectedMode === 'light', 'bg-black text-white': modeStore.selectedMode === 'dark'}" class="bg-opacity-30 rounded-xl p-[30px] min-w-[600px]">
                        <thead class="text-base font-semibold rounded-t-xl" :class="{' bg-white bg-opacity-30': modeStore.selectedMode === 'light', 'bg-black bg-opacity-30': modeStore.selectedMode === 'dark'}">
                            <tr class="grid grid-cols-12 py-2 px-6 rounded-t-xl min-w-[600px]">
                                <th class="col-span-6 text-center">{{ $t('playlistPage.title')}}</th>
                                <!-- <th class="hidden md:block col-span-3 text-center">Dimuatkan oleh</th> -->
                                <th class="col-span-3 text-center">{{ $t('playlistPage.fileType')}}</th>
                                <th class="col-span-3 text-center">{{ $t('playlistPage.action')}}</th>
                            </tr>
                        </thead>
                        <div v-for="document in allSearchResultStore.allSearchResult.assets" :key="document.id" class="min-w-[600px]">
                            <div class="grid grid-cols-12 py-3 md:px-6 w-full" :class="{' bg-white bg-opacity-30 hover:bg-opacity-10': modeStore.selectedMode === 'light', 'bg-black bg-opacity-30 hover:bg-opacity-10': modeStore.selectedMode === 'dark'}">
                                <div class="col-span-6 flex gap-4">
                                    <div class="min-w-12 w-12 h-12 rounded-full">
                                        <img :src="document.thumbnails[0]?.url" alt="playlist image" class="w-full h-full object-cover">
                                    </div>
                                    <div class="my-auto">
                                        <p class="text-sm md:text-base font-semibold">{{ document.title }}</p>
                                        <div >
                                            <div class="text-sm md:text-base">
                                                {{ document.groups?.map(group => group.name).join(' | ') }}
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                                <!-- <td class="col-span-3 text-center">
                                    <p v-if="document.created_by" class="text-sm">{{ document.created_by?.name }}</p>
                                    <p v-else class="text-sm">-</p>
                                </td> -->
                                <td class="col-span-3 text-center my-auto">
                                    <div             
                                :class="{
                                    'bg-[#FEF3C7] text-[#92400E]': document.content_type === 1,
                                    'bg-green-100 text-green-800': document.content_type === 2,
                                    'bg-gray-100 text-gray-800': document.content_type === 3
                                }" class="badge badge-lg border-0 rounded-xl">
                                    {{ getContentTypeLabel(document.content_type) }}
                                </div>
                                </td>
                                <td class="col-span-3 text-center my-auto mx-auto">
                                    <div v-if="document.has_added_to_workspace" @click="removeFromWorkspace(document.id)"  class="my-auto cursor-pointer">
                                        <div v-show="isAddRemoveWorkspaceLoading" class="spinner" :style="{borderLeftColor: strokeColor}"></div>
                                        <svg v-show="!isAddRemoveWorkspaceLoading" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M32.5 21.25V26.25C32.5 30.625 30 32.5 26.25 32.5H13.75C10 32.5 7.5 30.625 7.5 26.25V21.25C7.5 17.9375 8.9375 16.0625 11.25 15.35C12 15.1125 12.8375 15 13.75 15H26.25C27.1625 15 28 15.1125 28.75 15.35C31.0625 16.0625 32.5 17.9375 32.5 21.25Z" :stroke="strokeColor" stroke-width="2.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M28.75 13.75V15.35C28 15.1125 27.1625 15 26.25 15H13.75C12.8375 15 12 15.1125 11.25 15.35V13.75C11.25 12.375 12.375 11.25 13.75 11.25H26.25C27.625 11.25 28.75 12.375 28.75 13.75Z" :stroke="strokeColor" stroke-width="2.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M25 9.38751V11.25H15V9.38751C15 8.35001 15.85 7.5 16.8875 7.5H23.1125C24.15 7.5 25 8.35001 25 9.38751Z" :stroke="strokeColor" stroke-width="2.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>  
                                    </div>
                                    <div v-else @click="addToWorkspace(document.id)" @mouseover="hoveredDocument = document.id" @mouseout="hoveredDocument = null" class="my-auto cursor-pointer text-black">
                                        <div v-show="isAddRemoveWorkspaceLoading" class="spinner" :style="{borderLeftColor: strokeColor}"></div>
                                        <svg v-show="!isAddRemoveWorkspaceLoading" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M32.5 21.25V26.25C32.5 30.625 30 32.5 26.25 32.5H13.75C10 32.5 7.5 30.625 7.5 26.25V21.25C7.5 17.9375 8.9375 16.0625 11.25 15.35C12 15.1125 12.8375 15 13.75 15H26.25C27.1625 15 28 15.1125 28.75 15.35C31.0625 16.0625 32.5 17.9375 32.5 21.25Z" 
                                            :stroke="hoveredDocument === document.id ? strokeColor : modeStrokeColor" 
                                            stroke-width="2.25" 
                                            stroke-miterlimit="10" 
                                            stroke-linecap="round" 
                                            stroke-linejoin="round"/>
                                            <path d="M28.75 13.75V15.35C28 15.1125 27.1625 15 26.25 15H13.75C12.8375 15 12 15.1125 11.25 15.35V13.75C11.25 12.375 12.375 11.25 13.75 11.25H26.25C27.625 11.25 28.75 12.375 28.75 13.75Z" 
                                            :stroke="hoveredDocument === document.id ? strokeColor : modeStrokeColor" 
                                            stroke-width="2.25" 
                                            stroke-miterlimit="10" 
                                            stroke-linecap="round" 
                                            stroke-linejoin="round"/>
                                            <path d="M25 9.38751V11.25H15V9.38751C15 8.35001 15.85 7.5 16.8875 7.5H23.1125C24.15 7.5 25 8.35001 25 9.38751Z" 
                                            :stroke="hoveredDocument === document.id ? strokeColor : modeStrokeColor" 
                                            stroke-width="2.25" 
                                            stroke-miterlimit="10" 
                                            stroke-linecap="round" 
                                            stroke-linejoin="round"/>
                                        </svg>  
                                    </div>
                                </td>
                            </div>
                        </div>
                    </table>
                </div>
            </div>

            <!--senarai cikgu for view all search result-->
            <div v-if="allSearchResultStore.allSearchResult.users" class="pt-6">
                <div 
                    class="flex items-center mb-4 gap-2"
                    :class="{'text-black': modeStore.selectedMode === 'light', 'text-white': modeStore.selectedMode === 'dark'}"
                >
                    <h2 :class="{'mobile-title': isMobile, 'title': !isMobile}">{{ $t('title.teacherList')}}</h2>
                    <router-link to="/teachers" class="title-action flex items-center"><i class="fa-solid fa-chevron-right fa-lg px-2"></i></router-link>
                </div>

                <div 
                    class="grid grid-cols-3 gap-x-4 gap-y-12 md:grid-cols-7 md:gap-4 justify-items-center"
                    :class="{'avatar-mobile-container' : isMobile}"
                >
                    <TeachersCard
                        class="md:min-w-0 lg:w-[80%]"
                        v-for="teacher in allSearchResultStore.allSearchResult.users"
                        :key="teacher.id"
                        :image="teacher.avatar_url"
                        :name="teacher.name"
                        :role="'Cikgu'"
                        :id="teacher.id"
                    />
                </div>
            </div>


            <!-- Playlist for view all search result -->
            <div v-if="allSearchResultStore.allSearchResult.playlists" class="pt-6">
                <div 
                    class="flex items-center mb-4 gap-2"
                    :class="{'text-black': modeStore.selectedMode === 'light', 'text-white': modeStore.selectedMode === 'dark'}"
                >
                    <h2 :class="{'mobile-title': isMobile, 'title': !isMobile}">Playlist</h2>
                    <router-link :to="{name: 'Playlist Page'}" class="title-action flex items-center"><i class="fa-solid fa-chevron-right fa-lg px-2"></i></router-link>
                </div>

                <div class="flex flex-row overflow-x-auto hide-scrollbar md:grid md:grid-cols-3 justify-items-center gap-4">
                    <PlaylistCard
                        class="min-w-full md:min-w-0 lg:w-full"
                        v-for="playlist in allSearchResultStore.allSearchResult.playlists"
                        :playlistId="playlist.id"
                        :key="playlist.id"
                        :image="playlist.playlist_thumbnail"
                        :subject="playlist.title"
                        :teacher="playlist.created_by?.name"
                        :grade="playlist.access_groups"
                        :documentCount="playlist.documentCount"
                        :interactionCount="playlist.interactive_count"
                        :videoCount="playlist.video_count"
                        :isAddedToWorkspace="playlist.has_added_to_workspace"
                        @refresh-playlist-api="refreshPlaylistInAllSearchResult"
                    />
                </div>
            </div>
        </section>
    </div>
</template>
import { defineStore } from 'pinia'
//temporary no use for this store, just get prepared for future use
//for user profile page, user cancel the changes after submit the form
export const useUserProfileStore = defineStore('userProfile', {
    state: () => {
      return {
        UserPrevName: '',
        UserPrevSchoolId: '',
        UserPrevLevelId: '',
        UserPrevGradeId: '',
        UserPrevStreamId: '',
        UserPrevGender: '',
      }
    },
    actions: {
        setUserPrevName(value) {
            this.UserPrevName = value
        },
        setUserPrevSchoolId(value) {
            this.UserPrevSchoolId = value
        },
        setUserPrevLevelId(value) {
            this.UserPrevLevelId = value
        },
        setUserPrevGradeId(value) {
            this.UserPrevGradeId = value
        },
        setUserPrevStreamId(value) {
            this.UserPrevStreamId = value
        },
        setUserPrevGender(value) {
            this.UserPrevGender = value
        },
        resetUserProfile() {
            this.UserPrevName = ''
            this.UserPrevSchoolId = ''
            this.UserPrevLevelId = ''
            this.UserPrevGradeId = ''
            this.UserPrevStreamId = ''
            this.UserPrevGender = ''
        },
    },
    persist: true,
  })
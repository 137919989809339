<script setup>
import { ref, onMounted } from 'vue'
import { useModeStore } from '../store/mode.js';
import useIsMobile from '../composables/isMobile.js';
import axios from 'axios';
import { useActivePageStore } from '../store/activePageTab.js';
import { useAddToPlaylistStore } from '../store/addToPlaylist.js';
import { useNotificationTopBarStore } from '../store/notificationTopBar.js';

import PageNavigator from '../components/PageNavigator.vue';
import PlaylistCard from '../components/PlaylistCard.vue'
import PlaylistCardSkeletonLoader from '../components/PlaylistCardSkeletonLoader.vue'
import DocumentsCard from '../components/DocumentsCard.vue'
import DocumentSkeletonCard from '@/components/DocumentSkeletonCard.vue';
import CreateEditPlaylistModal from '../components/CreateEditPlaylistModal.vue';
import AddOrCreatePlaylistTopBar from '../components/AddOrCreatePlaylistTopBar.vue';
import AddToPlaylistDropdown from '../components/AddToPlaylistDropdown.vue';
import NotificationTopBar from '../components/NotificationTopBar.vue';

const modeStore = useModeStore();
const { isMobile } = useIsMobile();

const isPlaylistLoading = ref(false);
const isAssetBroadcastsLoading = ref(false);
const isAssetBroadcastsLatestLoading = ref(false);
const playlistData = ref([]);
const assetBroadcastsData = ref([]);
const assetBroadcastsLatestData = ref([]);
const addToPlaylistStore = useAddToPlaylistStore();
const activePageStore = useActivePageStore();
const notificationTopBarStore = useNotificationTopBarStore();


onMounted(() => {
    addToPlaylistStore.closeAddOrCreatePlaylistTopBar();
    activePageStore.setSelectedActivePage(2)
    callPlaylistApi();
    callAssetBroadcastApi();
    callAssetBroadcastLatestApi();
});

function callPlaylistApi() {
    // get api/v1/playlists
    isPlaylistLoading.value = true;
    axios.get('api/v1/playlists?per_page=3&status=1').then(response => {
        playlistData.value = response.data.data;
    }).catch(error => {
        console.error('Failed to fetch playlists data:', error);
    }).finally(() => {
        isPlaylistLoading.value = false
    });
}


function callAssetBroadcastApi() {
    // get api/v1/asset-broadcasts
    isAssetBroadcastsLoading.value = true;
    axios.get('api/v1/asset-broadcasts').then(response => {
        assetBroadcastsData.value = response.data.data;
    }).catch(error => {
        console.error('Failed to fetch asset broadcasts data:', error);
    }).finally(() => {
        isAssetBroadcastsLoading.value = false
    });
}

function callAssetBroadcastLatestApi() {
    // get api/v1/asset-broadcasts
    isAssetBroadcastsLatestLoading.value = true;
    axios.get('api/v1/asset-broadcasts?order_by=latest&per_page=4').then(response => {
        assetBroadcastsLatestData.value = response.data.data;
    }).catch(error => {
        console.error('Failed to fetch asset broadcasts latest data:', error);
    }).finally(() => {
        isAssetBroadcastsLatestLoading.value = false
    });
}

</script>

<template>
    <div>
        <NotificationTopBar v-show="notificationTopBarStore.isShowNotificationTopBar" />
        <AddOrCreatePlaylistTopBar v-show="addToPlaylistStore.showAddOrCreatePlaylistTopBar" />
        <AddToPlaylistDropdown v-show="addToPlaylistStore.showAddToPlaylistDropdown" />
        <CreateEditPlaylistModal v-show="addToPlaylistStore.showCreateOrEditPlaylistModal" :isAtPlaylistPages="false" />
        <page-navigator from="Laman Utama" to="Browse" />
        <div :class="{'text-black': modeStore.selectedMode === 'light', ' text-white': modeStore.selectedMode === 'dark'}" class="flex justify-between justify-items-center my-auto py-4 px-[30px]">
            <h2 :class="{'mobile-title': isMobile, 'page-top-title': !isMobile}">{{ $t('title.browse') }}</h2>
        </div>
        <section 
            class="flex flex-col gap-8 rounded-xl p-[30px] bg-transparent"
            :class="{'bg-transparent': modeStore.selectedMode === 'light', 'bg-custom-blue-gray text-white': modeStore.selectedMode === 'dark'}"
        >


            <div>
                <div 
                    class="flex items-center mb-4 gap-2"
                    :class="{'text-black': modeStore.selectedMode === 'light', 'text-white': modeStore.selectedMode === 'dark'}"
                >
                    <h2 :class="{'mobile-title': isMobile, 'title': !isMobile}">Playlist</h2>
                    <router-link :to="{name : 'Playlist Page'}" class="title-action"><i class="fa-solid fa-chevron-right fa-lg px-2 my-auto"></i></router-link>
                </div>

                <!-- <div v-show="isPlaylistLoading" class="flex items-center justify-center w-full gap-2">
                    <div>{{ $t('general.loading')}}</div>
                    <span class="loading loading-bars loading-sm"></span>
                </div> -->
                <div v-show="isPlaylistLoading" class="flex flex-row overflow-x-auto md:grid md:grid-cols-3 justify-items-center gap-4">
                    <PlaylistCardSkeletonLoader :dark="modeStore.selectedMode === 'dark'" class="min-w-full md:min-w-0 lg:w-full"/>
                    <PlaylistCardSkeletonLoader :dark="modeStore.selectedMode === 'dark'" class="min-w-full md:min-w-0 lg:w-full"/>
                    <PlaylistCardSkeletonLoader :dark="modeStore.selectedMode === 'dark'" class="min-w-full md:min-w-0 lg:w-full"/>
                </div>

                <div v-show="!isPlaylistLoading" class="flex flex-row overflow-x-auto md:grid md:grid-cols-3 justify-items-center gap-4">
                    <PlaylistCard
                        class="min-w-full md:min-w-0 lg:w-full"
                        v-for="playlist in playlistData"
                        :key="playlist.id"
                        :playlistId="playlist.id"
                        :image="playlist.playlist_thumbnail"
                        :subject="playlist.title"
                        :teacher="playlist.created_by?.name"
                        :grade="playlist.access_groups"
                        :documentCount="playlist.document_count"
                        :interactionCount="playlist.interactive_count"
                        :videoCount="playlist.video_count"
                        :isAddedToWorkspace="playlist.has_added_to_workspace"
                        @refresh-playlist-api="callPlaylistApi"
                    />
                </div>
            </div>

            <div>
                <div 
                    class="flex items-center mb-4 gap-2"
                    :class="{'text-black': modeStore.selectedMode === 'light', 'text-white': modeStore.selectedMode === 'dark'}"
                >
                    <h2 :class="{'mobile-title': isMobile, 'title': !isMobile}">{{ $t('title.DELIMaCollection')}}</h2>
                    <router-link :to="{name : 'Document Page'}" class="title-action"><i class="fa-solid fa-chevron-right fa-lg px-2 my-auto"></i></router-link>
                </div>

                <!-- <div v-show="isAssetBroadcastsLoading" class="flex items-center justify-center w-full gap-2">
                    <div>{{ $t('general.loading')}}</div>
                    <span class="loading loading-bars loading-sm"></span>
                </div> -->
                <div v-show="isAssetBroadcastsLoading" 
                    class="grid grid-cols-2 gap-y-12 md:grid-cols-4 md:gap-4 justify-items-center"
                    :class="{'document-mobile-container' : isMobile}"
                >
                    <DocumentSkeletonCard :dark="modeStore.selectedMode === 'dark'" class="min-w-full md:min-w-0 lg:w-full"/>
                    <DocumentSkeletonCard :dark="modeStore.selectedMode === 'dark'" class="min-w-full md:min-w-0 lg:w-full"/>
                    <DocumentSkeletonCard :dark="modeStore.selectedMode === 'dark'" class="min-w-full md:min-w-0 lg:w-full"/>
                    <DocumentSkeletonCard :dark="modeStore.selectedMode === 'dark'" class="min-w-full md:min-w-0 lg:w-full"/>
                </div>
                
                <div v-show="!isAssetBroadcastsLoading" 
                    class="grid grid-cols-2 gap-y-12 md:grid-cols-4 md:gap-4 justify-items-center"
                    :class="{'document-mobile-container' : isMobile}"
                >
                <DocumentsCard
                        class="md:min-w-0 lg:w-full"
                        v-for="asset in assetBroadcastsData.slice(0, 4)"
                        :key="asset.id"
                        :id="asset.id"
                        :image="asset.thumbnails[0]?.url"
                        :topic="asset.title"
                        :groups="asset.groups ? asset.groups : []"
                        :subjects="asset.subjects ? asset.subjects : []"
                        :postedTime="asset.created_at_formatted"
                        :tags="asset.tags"
                        :isAddedToWorkspace="asset.has_added_to_workspace"
                        :contentType="asset.content_type"
                        @refresh-asset-api="callAssetBroadcastApi"
                    />
                </div>
            </div>

            <div>
                <div 
                    class="flex items-center mb-4 gap-2"
                    :class="{'text-black': modeStore.selectedMode === 'light', 'text-white': modeStore.selectedMode === 'dark'}"
                >
                    <h2 :class="{'mobile-title': isMobile, 'title': !isMobile}">{{ $t('title.newPublish')}}</h2>
                    <router-link :to="{name : 'Document Page'}" class="title-action"><i class="fa-solid fa-chevron-right fa-lg px-2 my-auto"></i></router-link>
                </div>

                <!-- <div v-show="isAssetBroadcastsLatestLoading" class="flex items-center justify-center w-full gap-2">
                    <div>{{ $t('general.loading')}}</div>
                    <span class="loading loading-bars loading-sm"></span>
                </div> -->
                <div v-show="isAssetBroadcastsLatestLoading" 
                    class="grid grid-cols-2 gap-y-12 md:grid-cols-4 md:gap-4 justify-items-center"
                    :class="{'document-mobile-container' : isMobile}"
                >
                    <DocumentSkeletonCard :dark="modeStore.selectedMode === 'dark'" class="min-w-full md:min-w-0 lg:w-full"/>
                    <DocumentSkeletonCard :dark="modeStore.selectedMode === 'dark'" class="min-w-full md:min-w-0 lg:w-full"/>
                    <DocumentSkeletonCard :dark="modeStore.selectedMode === 'dark'" class="min-w-full md:min-w-0 lg:w-full"/>
                    <DocumentSkeletonCard :dark="modeStore.selectedMode === 'dark'" class="min-w-full md:min-w-0 lg:w-full"/>
                </div>

                <div v-show="!isAssetBroadcastsLatestLoading" 
                    class="grid grid-cols-2 gap-y-12 md:grid-cols-4 md:gap-4 justify-items-center"
                    :class="{'document-mobile-container' : isMobile}"
                >
                    <DocumentsCard
                        class="md:min-w-0 lg:w-full"
                        v-for="asset in assetBroadcastsLatestData"
                        :key="asset.id"
                        :id="asset.id"
                        :image="asset.thumbnails[0]?.url"
                        :topic="asset.title"
                        :groups="asset.groups ? asset.groups : []"
                        :subjects="asset.subjects ? asset.subjects : []"
                        :postedTime="asset.created_at_formatted"
                        :tags="asset.tags"
                        :isAddedToWorkspace="asset.has_added_to_workspace"
                        :contentType="asset.content_type"
                        @refresh-asset-api="callAssetBroadcastApi"
                    />
                </div>
            </div>


        </section>
    </div>
</template>
<script setup>
import axios from 'axios';
import { ref, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useModeStore } from '../store/mode.js';
import PlaylistCardForTeacher from '../components/PlaylistCardForTeacher.vue';
import { useThemeStore } from '../store/theme.js';
import { useAllSearchResultStore } from '../store/allSearchResult.js';
import CustomPagination from '../components/CustomPagination.vue';
import PageNavigator from '../components/PageNavigator.vue';
import { getStrokeColor } from '../utils/commonFunctions.js';

const modeStore = useModeStore();
const themeStore = useThemeStore();
const allSearchResultStore = useAllSearchResultStore();
const strokeColor = getStrokeColor();
const route = useRoute();
const teacherId = route.params.id;
const isTeacherPlaylistLoading = ref(false);
const currentPage = ref(0);
const itemsPerPage = ref(12); // Default itemsPerPage 10
const totalItems = ref(0);
const teacherPlaylistsData = ref([]);
const teacherName = ref('');
const teacherAvatar = ref('');
const teacherSchool = ref('');
const teacherStats = ref({
    stats: {
        total_asset_active: 0,
        total_playlist: 0,
        total_interaction: 0
    }
});

const showingFrom = computed(() => {
    return (currentPage.value - 1) * itemsPerPage.value + 1;
});

const showingTo = computed(() => {
    // If this is the last page, return the total items, else return the current page * items per page
    return totalItems.value < currentPage.value * itemsPerPage.value ? totalItems.value : currentPage.value * itemsPerPage.value;
});

const totalPages = computed(() => {
    return Math.ceil(totalItems.value / itemsPerPage.value);
});

const changePage = (page) => {
    currentPage.value = page;
    // get api/v1/playlists/teacher/{userId}?page=newPage
    isTeacherPlaylistLoading.value = true;

    axios.get(`api/v1/playlists/teacher/${teacherId}?page=${page}`)
        .then((response) => {
            currentPage.value = response.data.current_page;
            showingTo.value = response.data.to;
            totalItems.value = response.data.total;
            teacherPlaylistsData.value = response.data.data;
        })
        .catch((error) => {
            console.error('Failed to fetch teacher playlists data:', error);
        })
        .finally(() => {
            isTeacherPlaylistLoading.value = false;
        });
};

const getTeacherStats = () => {
    axios.get('api/v1/playlists/teacher/stats', {
        params: {
            userId: teacherId
        }
    })
    .then((response) => {
        teacherStats.value = response.data;
    })
    .catch((error) => {
        console.log(error);
    });
};

onMounted(() => {
    allSearchResultStore.setShowSearchResult(false);
    callTeacherPlaylistApi();
    getTeacherStats();
});

function callTeacherPlaylistApi() {
    // get api/v1/playlists/teacher/{userId}
    isTeacherPlaylistLoading.value = true;
    axios.get(`api/v1/playlists/teacher/${teacherId}`)
        .then((response) => {
            currentPage.value = response.data.current_page;
            showingTo.value = response.data.to;
            totalItems.value = response.data.total;
            teacherPlaylistsData.value = response.data.data;
            teacherName.value = teacherPlaylistsData.value[0].created_by?.name;
            teacherAvatar.value = teacherPlaylistsData.value[0].created_by?.avatar_url;
            teacherSchool.value = teacherPlaylistsData.value[0].created_by?.school;
            teacherStats.value.stats.total_playlist = response.data.total;
        })
        .catch((error) => {
            console.error('Failed to fetch teacher playlists data:', error);
        })
        .finally(() => {
            isTeacherPlaylistLoading.value = false;
        });
}
</script>

<template>
    <section class="flex flex-col min-h-screen justify-start">
        <!-- Breadcrumb -->
        <page-navigator from="Playlist" to="Senarai Cikgu" :subPage="teacherName" />
        <div :class="{'text-black': modeStore.selectedMode === 'light', ' text-white': modeStore.selectedMode === 'dark'}" class="flex justify-between justify-items-center p-4">
            <h2 :class="{'mobile-title': isMobile, 'page-top-title': !isMobile}">Playlist Cikgu</h2>
        </div>
        <!-- <div 
            class="flex flex-row gap-2 items-center text-base leading-6 font-medium mb-4"
            :class="{ 'text-black': modeStore.selectedMode === 'light', 'text-white': modeStore.selectedMode === 'dark'}"
        >
            <router-link to="/teachers">Senarai Cikgu</router-link>
            <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.56836 12.9508L9.64336 8.87578C10.1246 8.39453 10.1246 7.60703 9.64336 7.12578L5.56836 3.05078" :stroke="strokeColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <p>Aznida Faizal</p>
        </div> -->

        <div v-show="isTeacherPlaylistLoading" class="flex items-center justify-center w-full gap-2 p-24">
            <div>{{ $t('general.loading')}}</div>
            <span class="loading loading-bars loading-sm"></span>
        </div>

        <div v-show="!isTeacherPlaylistLoading">
            <!-- Teacher Banner -->
            <div 
                class="flex flex-col lg:flex-row p-4 lg:p-6 rounded-xl bg-transparent"
                :class="{ 'text-black': modeStore.selectedMode === 'light', 'text-white': modeStore.selectedMode === 'dark' } "
            >
                <div class="flex items-center justify-center basis-1/5 rounded-xl overflow-hidden  lg:p-0">
                    <img class="w-full rounded-xl max-w-[120px] md:max-w-[200px]" :src="teacherAvatar" alt="Teacher's Image" />
                </div>
                <div class="flex flex-col gap-4 items-left justify-center md:py-6 px-2 lg:p-10 w-full basis-4/5 ">
                    <h2 class="text-center lg:text-left text-xl lg:text-6xl leading-none font-extrabold" :class="{ 'text-black': modeStore.selectedMode === 'light', 'text-white': modeStore.selectedMode === 'dark' }">{{ teacherName }}</h2>
                    <p class="text-center lg:text-left text-sm lg:text-xl leading-7 font-medium  lg:pb-0">{{ teacherSchool }}</p>
                </div>
            </div>

            <!-- metadata-->
            <div :class="{'text-black': modeStore.selectedMode === 'light', 'text-white': modeStore.selectedMode === 'dark'}" class="grid grid-cols-3 gap-2 md:gap-4">
                    <div :class="[{'bg-white bg-opacity-30': modeStore.selectedMode === 'light', 'dark-mode-metadata-bar-orange-bg-1': (modeStore.selectedMode === 'dark' && themeStore.selectedTheme === 'orange'), 'dark-mode-metadata-bar-green-bg-1': (modeStore.selectedMode === 'dark' && themeStore.selectedTheme === 'green'), 'dark-mode-metadata-bar-blue-bg-1': (modeStore.selectedMode === 'dark' && themeStore.selectedTheme === 'blue'), 'dark-mode-metadata-bar-purple-bg-1': (modeStore.selectedMode === 'dark' && themeStore.selectedTheme === 'purple'), 'dark-mode-metadata-bar-darkpink-bg-1': (modeStore.selectedMode === 'dark' && themeStore.selectedTheme === 'darkpink')}]" class="p-4 rounded-3xl">
                        <div class="flex flex-col md:flex-row md:gap-4">
                            <div class="metadata-clock my-auto">
                                <svg v-if="modeStore.selectedMode === 'dark'" class="max-w-[30px] md:max-w-[50px] lg:max-w-none" width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="50" height="50" rx="25" fill="#FFF7ED" fill-opacity="0.2"/>
                                <path d="M27.3625 13.6012L21.5 12.6762C16.6125 11.9137 14.35 13.5637 13.575 18.4512L12.65 24.3137C12.15 27.5137 12.675 29.5887 14.4875 30.8512C15.4375 31.5262 16.7375 31.9762 18.425 32.2387L24.2875 33.1637C29.175 33.9262 31.4375 32.2762 32.2125 27.3887L33.125 21.5262C33.275 20.5637 33.3375 19.7012 33.2875 18.9387C33.125 15.8137 31.2875 14.2137 27.3625 13.6012ZM20.3 21.6887C18.8375 21.6887 17.65 20.5012 17.65 19.0512C17.65 17.5887 18.8375 16.4012 20.3 16.4012C21.75 16.4012 22.9375 17.5887 22.9375 19.0512C22.9375 20.5012 21.75 21.6887 20.3 21.6887Z" fill="white"/>
                                <path d="M35.6258 26.8367L33.7508 32.4742C32.1883 37.1742 29.6883 38.4242 24.9883 36.8617L19.3508 34.9867C17.5883 34.3992 16.3133 33.6742 15.4883 32.7617C16.2758 33.0742 17.1883 33.3117 18.2258 33.4742L24.1008 34.3992C24.9008 34.5242 25.6508 34.5867 26.3508 34.5867C30.4758 34.5867 32.6883 32.3617 33.4508 27.5742L34.3633 21.7117C34.4883 20.9867 34.5383 20.3492 34.5383 19.7617C36.4383 21.3242 36.7133 23.5492 35.6258 26.8367Z" fill="white"/>
                                </svg>
                                <svg v-else class="max-w-[30px] md:max-w-none" width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="50" height="50" rx="25" :fill=strokeColor fill-opacity="0.2"/>
                                <path d="M27.3625 13.6012L21.5 12.6762C16.6125 11.9137 14.35 13.5637 13.575 18.4512L12.65 24.3137C12.15 27.5137 12.675 29.5887 14.4875 30.8512C15.4375 31.5262 16.7375 31.9762 18.425 32.2387L24.2875 33.1637C29.175 33.9262 31.4375 32.2762 32.2125 27.3887L33.125 21.5262C33.275 20.5637 33.3375 19.7012 33.2875 18.9387C33.125 15.8137 31.2875 14.2137 27.3625 13.6012ZM20.3 21.6887C18.8375 21.6887 17.65 20.5012 17.65 19.0512C17.65 17.5887 18.8375 16.4012 20.3 16.4012C21.75 16.4012 22.9375 17.5887 22.9375 19.0512C22.9375 20.5012 21.75 21.6887 20.3 21.6887Z" :fill=strokeColor />
                                <path d="M35.6258 26.8367L33.7508 32.4742C32.1883 37.1742 29.6883 38.4242 24.9883 36.8617L19.3508 34.9867C17.5883 34.3992 16.3133 33.6742 15.4883 32.7617C16.2758 33.0742 17.1883 33.3117 18.2258 33.4742L24.1008 34.3992C24.9008 34.5242 25.6508 34.5867 26.3508 34.5867C30.4758 34.5867 32.6883 32.3617 33.4508 27.5742L34.3633 21.7117C34.4883 20.9867 34.5383 20.3492 34.5383 19.7617C36.4383 21.3242 36.7133 23.5492 35.6258 26.8367Z" :fill=strokeColor />
                                </svg>
                            </div>
                            <div>
                                <div v-if="teacherStats.stats.total_asset_active > 1 "><span class="font-semibold text-sm md:text-lg">{{ teacherStats.stats.total_asset_active }}</span> {{ $t('teacherMetaDataBar.assets')}}</div>
                                <div v-else><span class="font-semibold text-sm md:text-lg">{{ teacherStats.stats.total_asset_active }}</span> {{ $t('teacherMetaDataBar.asset')}}</div>
                                <div class="font-semibold text-sm md:text-lg">{{ $t('teacherMetaDataBar.activeInWorkspace')}}</div>
                            </div>
                        </div>
                    </div>
                    <router-link :to="{'name': 'Playlist Page'}" :class="[{'bg-white bg-opacity-30': modeStore.selectedMode === 'light', 'dark-mode-metadata-bar-orange-bg-2': (modeStore.selectedMode === 'dark' && themeStore.selectedTheme === 'orange'), 'dark-mode-metadata-bar-green-bg-2': (modeStore.selectedMode === 'dark' && themeStore.selectedTheme === 'green'), 'dark-mode-metadata-bar-blue-bg-2': (modeStore.selectedMode === 'dark' && themeStore.selectedTheme === 'blue'), 'dark-mode-metadata-bar-purple-bg-2': (modeStore.selectedMode === 'dark' && themeStore.selectedTheme === 'purple'), 'dark-mode-metadata-bar-darkpink-bg-2': (modeStore.selectedMode === 'dark' && themeStore.selectedTheme === 'darkpink')}]" class="p-4 rounded-3xl">
                        <div class="flex flex-col md:flex-row md:gap-4">
                            <div class="metadata-playlist my-auto">
                                <svg v-if="modeStore.selectedMode === 'dark'" class="max-w-[30px] md:max-w-[50px] lg:max-w-none" width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.667969" y="0.5" width="50" height="50" rx="25" fill="#FFF7ED" fill-opacity="0.2"/>
                                <path d="M25.668 13.0029C18.768 13.0029 13.168 18.6029 13.168 25.5029C13.168 32.4029 18.768 38.0029 25.668 38.0029C32.568 38.0029 38.168 32.4029 38.168 25.5029C38.168 18.6029 32.568 13.0029 25.668 13.0029ZM28.993 27.6654L27.393 28.5904L25.793 29.5154C23.7305 30.7029 22.043 29.7279 22.043 27.3529V25.5029V23.6529C22.043 21.2654 23.7305 20.3029 25.793 21.4904L27.393 22.4154L28.993 23.3404C31.0555 24.5279 31.0555 26.4779 28.993 27.6654Z" fill="white"/>
                                </svg>
                                <svg v-else class="max-w-[30px] md:max-w-[50px] lg:max-w-none" width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.667969" y="0.5" width="50" height="50" rx="25" :fill=strokeColor fill-opacity="0.2"/>
                                <path d="M25.668 13.0029C18.768 13.0029 13.168 18.6029 13.168 25.5029C13.168 32.4029 18.768 38.0029 25.668 38.0029C32.568 38.0029 38.168 32.4029 38.168 25.5029C38.168 18.6029 32.568 13.0029 25.668 13.0029ZM28.993 27.6654L27.393 28.5904L25.793 29.5154C23.7305 30.7029 22.043 29.7279 22.043 27.3529V25.5029V23.6529C22.043 21.2654 23.7305 20.3029 25.793 21.4904L27.393 22.4154L28.993 23.3404C31.0555 24.5279 31.0555 26.4779 28.993 27.6654Z" :fill=strokeColor />
                                </svg>
                            </div>
                            <div>
                                <!-- <div v-if="userStats.unique_playlist_watched && userStats.unique_playlist_watched > 1"><span class="font-semibold text-lg">{{ userStats.unique_playlist_watched }}</span> {{ $t('dashboardMetaDataBar.playlists')}}</div> -->
                                <div v-if="teacherStats.stats.total_playlist > 1"><span class="font-semibold text-sm md:text-lg">{{ teacherStats.stats.total_playlist }}</span> {{ $t('dashboardMetaDataBar.playlists')}}</div>
                                <div v-else><span class="font-semibold text-sm md:text-lg">{{ teacherStats.stats.total_playlist }}</span> {{ $t('dashboardMetaDataBar.playlist')}}</div>
                                <div class="font-semibold text-sm md:text-lg">{{ $t('dashboardMetaDataBar.fromTeacher')}}</div>
                            </div>
                        </div>
                    </router-link>
                    <div :class="[{'bg-white bg-opacity-30': modeStore.selectedMode === 'light', 'dark-mode-metadata-bar-orange-bg-3': (modeStore.selectedMode === 'dark' && themeStore.selectedTheme === 'orange'), 'dark-mode-metadata-bar-green-bg-3': (modeStore.selectedMode === 'dark' && themeStore.selectedTheme === 'green'), 'dark-mode-metadata-bar-blue-bg-3': (modeStore.selectedMode === 'dark' && themeStore.selectedTheme === 'blue'), 'dark-mode-metadata-bar-purple-bg-3': (modeStore.selectedMode === 'dark' && themeStore.selectedTheme === 'purple'), 'dark-mode-metadata-bar-darkpink-bg-3': (modeStore.selectedMode === 'dark' && themeStore.selectedTheme === 'darkpink')}]" class="p-4 rounded-3xl">
                        <div class="flex flex-col md:flex-row md:gap-4">
                            <div class="metadata-playlist my-auto">
                                <svg v-if="modeStore.selectedMode === 'dark'" class="max-w-[30px] md:max-w-[50px] lg:max-w-none" width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="0.333984" width="50" height="50" rx="25" fill="#FFF7ED" fill-opacity="0.2"/>
                                    <path d="M30.5715 12.501H20.0965C15.5465 12.501 12.834 15.2135 12.834 19.7635V30.226C12.834 34.7885 15.5465 37.501 20.0965 37.501H30.559C35.109 37.501 37.8215 34.7885 37.8215 30.2385V19.7635C37.834 15.2135 35.1215 12.501 30.5715 12.501ZM32.534 28.351C32.9465 28.6635 33.034 29.251 32.7215 29.6635C32.534 29.9135 32.259 30.0385 31.9715 30.0385C31.7715 30.0385 31.584 29.976 31.409 29.851L26.4715 26.1635L23.459 28.4135C23.4715 28.5385 23.4965 28.6635 23.4965 28.7885C23.4965 30.376 22.209 31.6635 20.6215 31.6635C19.034 31.6635 17.7465 30.376 17.7465 28.7885C17.7465 27.201 19.034 25.9135 20.6215 25.9135C21.3965 25.9135 22.084 26.226 22.5965 26.7135L24.8965 24.9885L22.609 23.276C22.0965 23.776 21.384 24.0885 20.609 24.0885C19.0215 24.0885 17.734 22.801 17.734 21.2135C17.734 19.626 19.0215 18.3385 20.609 18.3385C22.1965 18.3385 23.484 19.626 23.484 21.2135C23.484 21.3385 23.459 21.451 23.4465 21.5635L26.4465 23.8135L31.384 20.126C31.7965 19.8135 32.384 19.901 32.6965 20.3135C33.009 20.726 32.9215 21.3135 32.509 21.626L28.009 24.9885L32.534 28.351Z" fill="white"/>
                                </svg>

                                <svg v-else class="max-w-[30px] md:max-w-[50px] lg:max-w-none" width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="0.333984" width="50" height="50" rx="25" :fill=strokeColor fill-opacity="0.2"/>
                                    <path d="M30.5715 12.501H20.0965C15.5465 12.501 12.834 15.2135 12.834 19.7635V30.226C12.834 34.7885 15.5465 37.501 20.0965 37.501H30.559C35.109 37.501 37.8215 34.7885 37.8215 30.2385V19.7635C37.834 15.2135 35.1215 12.501 30.5715 12.501ZM32.534 28.351C32.9465 28.6635 33.034 29.251 32.7215 29.6635C32.534 29.9135 32.259 30.0385 31.9715 30.0385C31.7715 30.0385 31.584 29.976 31.409 29.851L26.4715 26.1635L23.459 28.4135C23.4715 28.5385 23.4965 28.6635 23.4965 28.7885C23.4965 30.376 22.209 31.6635 20.6215 31.6635C19.034 31.6635 17.7465 30.376 17.7465 28.7885C17.7465 27.201 19.034 25.9135 20.6215 25.9135C21.3965 25.9135 22.084 26.226 22.5965 26.7135L24.8965 24.9885L22.609 23.276C22.0965 23.776 21.384 24.0885 20.609 24.0885C19.0215 24.0885 17.734 22.801 17.734 21.2135C17.734 19.626 19.0215 18.3385 20.609 18.3385C22.1965 18.3385 23.484 19.626 23.484 21.2135C23.484 21.3385 23.459 21.451 23.4465 21.5635L26.4465 23.8135L31.384 20.126C31.7965 19.8135 32.384 19.901 32.6965 20.3135C33.009 20.726 32.9215 21.3135 32.509 21.626L28.009 24.9885L32.534 28.351Z" :fill=strokeColor />
                                </svg>
                            </div>
                            <div>
                                <!-- <div v-if="assetBroadcastTotal > 1"><span class="font-semibold text-lg">{{ assetBroadcastTotal }}</span> {{ $t('dashboardMetaDataBar.files')}}</div> -->
                                <div><span class="font-semibold text-sm md:text-lg">{{ teacherStats.stats.total_interaction }}</span> {{ $t('teacherMetaDataBar.interaction')}}</div>
                                <div class="font-semibold text-sm md:text-lg">{{ $t('teacherMetaDataBar.fromStudent')}}</div>
                            </div>
                        </div>
                    </div>
                </div>

            <!-- Playlist -->
            <div
                class="flex flex-col gap-8 rounded-xl p-4 bg-transparent"
                :class="{'text-black': modeStore.selectedMode === 'light', 'text-white': modeStore.selectedMode === 'dark'}"
            >
                <!-- Title -->
                <div 
                    class="flex items-center justify-start"
                    :class="{'text-black': modeStore.selectedMode === 'light', 'text-white': modeStore.selectedMode === 'dark'}"
                >
                    <h2 class="text-4xl leading-10 font-extrabold" :class="{ 'text-black': modeStore.selectedMode === 'light', 'text-white': modeStore.selectedMode === 'dark' }">Senarai Playlist</h2>
                </div>

                <!-- Cards -->
                <div class="flex flex-col md:grid md:grid-cols-4 justify-items-center gap-4">
                    <PlaylistCardForTeacher
                        class="min-w-full md:min-w-0 lg:w-full"
                        v-for="list in teacherPlaylistsData"
                        :key="list.id"
                        :playlistId="list.id"
                        :playlistThumbnail="list.playlist_thumbnail"
                        :assetBroadcastThumbnails="list.asset_broadcast_thumbnails.length >= 3 ? list.asset_broadcast_thumbnails: null"
                        :subject="list.title"
                        :documentCount="list.document_count"
                        :interactionCount="list.interactive_count"
                        :videoCount="list.video_count"
                        :isAddedToWorkspace="list.has_added_to_workspace"
                        :isTeacherPlaylist="true"
                        @refresh-teacher-playlist-api="callTeacherPlaylistApi"
                    />
                </div>

                <!-- Pagination -->
                <div class="hidden lg:block">
                    <CustomPagination
                        :showingFrom="showingFrom"
                        :showingTo="showingTo"
                        :totalItems="totalItems"
                        :totalPages="totalPages"
                        :currentPage="currentPage"
                        :itemsPerPage="itemsPerPage"
                        @change-page="changePage"
                    />
                </div>
            </div>
        </div>
    </section>
</template>
<template>
  <div class="flex space-x-2">
    <div
      v-for="tab in tabs"
      :key="tab.value"
      @click="updateActiveTab(tab.value)"
      :class="[
        activeTab === tab.value
          ? [
              themeStore.selectedTheme === 'green' ? 'bg-[#14B8A6]' :
              themeStore.selectedTheme === 'orange' ? 'bg-[#F97316]' :
              themeStore.selectedTheme === 'blue' ? 'bg-[#3B82F6]' :
              themeStore.selectedTheme === 'purple' ? 'bg-[#8B5CF6]' :
              themeStore.selectedTheme === 'darkpink' ? 'bg-[#DB2777]' :
              modeStore.selectedMode === 'light' ? 'bg-white' : '',
              'text-white'
            ]
          : modeStore.selectedMode === 'dark'
            ? 'bg-black text-white'
            : 'bg-gray-50 text-black',
                // Hover classes based on theme and mode
            themeStore.selectedTheme === 'green' ? 'hover:bg-[#14B8A6]' :
            themeStore.selectedTheme === 'orange' ? 'hover:bg-[#F97316]' :
            themeStore.selectedTheme === 'blue' ? 'hover:bg-[#3B82F6]' :
            themeStore.selectedTheme === 'purple' ? 'hover:bg-[#8B5CF6]' :
            themeStore.selectedTheme === 'darkpink' ? 'hover:bg-[#DB2777]' :
            modeStore.selectedMode === 'light' ? 'hover:bg-gray-100' : '',
          'px-4 py-2 rounded-md cursor-pointer hover:text-white'
      ]"
    >
      {{ $t(tab.label) }}
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';
import { useModeStore } from '../store/mode.js';
import { useThemeStore } from '../store/theme.js';
const modeStore = useModeStore();
const themeStore = useThemeStore();

defineProps({
  activeTab: {
    type: String,
    required: true
  },
  tabs: {
    type: Array,
    required: true
  }
});

const emit = defineEmits(['update:activeTab']);


const updateActiveTab = (tabValue) => {
  emit('update:activeTab', tabValue);
};
</script>